.jumbotron {
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      /* Solid white on the left */ rgba(255, 255, 255, 0.1)
        /* Transparent on the right */
    ),
    url("../components/images/jumbotron-img4.jpg") no-repeat center center;
  background-size: 110% 130%; /* Adjusts image to cover the container */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 15%;
  padding: 3%;
  margin-top: 150px;
}

.green-button {
  display: flex;
  align-items: center;
  margin-top: 5%;
}

.container {
  height: 100%;
  width: 8%;
}

.circle,
.circle::before {
  content: " ";
  margin: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.3s;
  background-color: #00ff00;
}

.circle::before {
  animation: mymove 2s infinite;
  position: absolute;
  background-color: #00ff00;
}

@keyframes mymove {
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@media only screen and (min-height: 1000px) {
  .jumbotron {
    margin-top: 180px;
  }
}

@media only screen and (min-height: 1200px) {
  .jumbotron {
    margin-top: 220px;
  }
}

@media only screen and (min-height: 1400px) {
  .jumbotron {
    margin-top: 280px;
  }
}

@media only screen and (min-height: 1700px) {
  .jumbotron {
    margin-top: 330px;
  }
}
.j-text {
  color: black;
  width: 40%;
  font-weight: bold;
}

.j-text-p-1 {
  font-size: 2.15em;
}
.j-text-p-2 {
  line-height: 1.5;
  font-size: 1.2em;
  color: black;
}
.j-text a {
  display: inline-block;
  padding: 3%;
  width: 60%;
  background-color: rgb(40, 79, 207);
  color: white;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
}

.checklist-content {
  text-align: left;
}
.j-images {
  width: 20%;
  display: flex;
  flex-direction: column;
}
.j-image {
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  width: 75%;
  margin-bottom: 8%;
  padding: 1%;
  margin-left: 12%;
}

@media only screen and (max-width: 800px) {
  .jumbotron {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: auto;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1),
        /* Solid white on the left */ rgba(255, 255, 255, 0.6)
          /* Transparent on the right */
      ),
      url("../components/images/jumbotron-img4.jpg") no-repeat center center;
  }

  .j-text {
    width: 90%;
    text-align: center;
    margin-top: 5%;
  }

  .j-text a {
    margin-bottom: 5%;
    margin-top: 5%;
    padding: 4%;
    width: 70%;
    border-radius: 20px;
  }
  .j-images {
    display: none;
  }

  .j-image {
    width: 100%;
    margin-left: 0%;
  }

  .j-image img {
    width: 20%;
  }
}

@media only screen and (max-width: 400px) {
  .j-text a {
    width: 95%;
    padding: 8%;
  }

  .checklist-content {
    font-size: 0.7em;
  }
}
