* {
  box-sizing: border-box;
}

body,
html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #ffffff;
  color: #5c5c5c;
}

.footer {
  width: 100%;
  background-color: #454545;
  margin-top: 5%;
  padding: 2%;
  color: #848383;
}
