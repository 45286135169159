.services {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.services-call {
  text-align: center;
  width: 75%;
  margin: auto;
  margin-top: 2%;
}

.services-call h1 {
  font-size: 2.25em;
  font-weight: 100;
}

.services-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  margin-top: 5%;
}

@media only screen and (max-width: 970px) {
  .services-icons {
    flex-direction: column;
  }
}

.services-icons-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3%;
}

.line {
  width: 40px;
  height: 4px;
  background-color: #17077b;
}

.our-services-container {
  width: 80%;
  margin: 3% auto;
  background-color: #eeeded;
}

.our-services-container img {
  display: block;
  width: 88%;
  margin: 4% auto;
}

.our-services-textbox {
  border: 4px solid black;
  width: 93%;
  margin: auto;
  margin-bottom: 4%;
  padding: 10px;
  font-size: large;
  background: rgb(255, 255, 255);
}

.our-services-textbox h1 {
  text-align: center;
  color: #17077b;
}

.our-services-lists {
  display: flex;
  justify-content: space-evenly;
}

.our-services-lists ul {
  list-style-type: none;
  text-align: center;
}

.our-services-lists li {
  margin-bottom: 10px;
}

.call-now-img {
  width: 70%;
  margin: auto;
}

@media only screen and (max-width: 463px) {
  .our-services-lists {
    justify-content: center;
    flex-direction: column;
  }
}
