.contact {
  background-color: black;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 3%;
}

.contact-text {
  width: 20%;
}

.contact-text-p {
  font-size: 2.3em;
  margin-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .contact-text {
    width: 60%;
  }
}
