.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 37px;
  height: 17%;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  z-index: 1000;
}

.header-menu {
  display: flex;
  margin-left: 10%;
}

.menu-button {
  width: 35px;
  height: 40px;
  display: none;
  flex-direction: column;
  border: none;
  background: none;
  cursor: pointer;
  padding: 1px;
  padding-top: 4px;
  margin: auto;
  margin-top: 32px;
}

.menu-bar {
  display: block;
  width: 90%;
  height: 5px; /* Thickness of the lines */
  background-color: rgb(110, 109, 109); /* Color of the hamburger lines */
  border-radius: 2px; /* Slight rounding on edges */
  margin-left: 1.5px;
  margin-top: 1.5px;
  margin-bottom: 3px;
}

.header img {
  height: 80px;
  margin-top: 1.5%;
}

.navbar-container {
  width: 100%;
  margin-bottom: 5%;
}

.navbar-container ul {
  list-style-type: none;
  display: flex;
  gap: 5%;
  margin-top: 13%;
  padding-left: 28%;
  font-size: 1.1em;
}

.navbar-container li a {
  text-decoration: none;
  color: rgb(110, 109, 109);
  font-weight: bold;
}

@media only screen and (max-width: 1300px) {
  .navbar-container {
    display: none;
  }

  .menu-button {
    display: flex;
  }
}

.navbar-container.visible {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  z-index: 1000;
}

.navbar-container.visible ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
  margin-top: 3%;
}
.navbar-container.visible li {
  margin-bottom: 9%;
}

.call-to-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0;
  width: 48%;
}

.call-to-action p {
  margin: 0;
}

.navbar-tel {
  text-decoration: none;
  font-size: 28px;
  font-weight: bold;
}

.call-to-action img {
  height: 40px;
  width: 40px;
  margin-right: 14px;
}

.cta-right {
  padding-left: 20px;
  padding-top: 12px;
}

.cta-right-text {
  font-weight: bold;
  font-size: 14px;
  color: rgb(110, 109, 109);
  padding-bottom: 3px;
}

@media only screen and (min-width: 1300px) {
  .call-to-action {
    padding-right: 10%;
  }
}
@media only screen and (max-width: 879px) {
  .cta-right a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .navbar-container.visible {
    margin-bottom: -15%;
  }
}

@media only screen and (max-width: 470px) {
  .call-to-action > a {
    display: none;
  }

  .call-to-action {
    padding-right: 5%;
  }
  .navbar-container.visible {
    margin-bottom: -30%;
  }

  .navbar-container.visible ul {
    font-size: 1em;
  }
}
