.about {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 5%;
}

.about-text {
  width: 50%;
  font-size: 1.4em;
}
.about-text h2 {
  padding-left: 5%;
  font-size: 1.7em;
}
.about img {
  margin-left: 5%;
}

@media only screen and (max-width: 985px) {
  .about {
    justify-content: center;
  }
  .about img {
    display: none;
  }

  .about-text h2 {
    text-align: center;
  }

  .about-text {
    width: 80%;
    font-size: 1.8em;
  }
}

@media only screen and (max-width: 442px) {
  .about-text {
    font-size: 1em;
  }
}
