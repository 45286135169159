.reviews {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 6%;
  padding-bottom: 9%;
}
.reviews-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reviews-left h1 {
  color: red;
  font-weight: bolder;
  font-size: 2.1em;
}

.customer-review p {
  text-align: center;
  margin-top: 10%;
}

.customer-review img {
}
.reviews-right {
  width: 35%;
  text-align: center;
}

.rr-text {
  margin-bottom: 9%;
  font-size: 1.5em;
}
.reviews-right-box {
  border: 1px solid #5c5c5c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6%;
  width: 100%;
  height: 36%;
  border-radius: 2px;
}

.reviews-right-box h2 {
  font-size: 1.2em;
  color: black;
}

@media only screen and (max-width: 650px) {
  .reviews {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .reviews-left {
    width: 100%;
    text-align: center;
  }

  .reviews-right {
    width: 100%;
    margin-top: 10%;
  }
}
