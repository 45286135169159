.map-text {
  position: absolute;
  top: 100px;
  left: 20px;
  background-color: white;
  padding: 2%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 30%;
}

@media only screen and (max-width: 550px) {
  .map-text {
    display: none;
  }
}
