.banner {
  background-color: #ec2a38;
  width: 100%;
  height: 40px; /*fixed height needed*/
  position: fixed;
  top: 0;
  z-index: 1000;
}

.banner-content {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  height: 56%;
  margin-left: 11%;
}
.banner-content p {
  color: rgb(255, 255, 255);
  font-size: 15px;
}

.banner-content-left {
  font-weight: bold;
}

@media only screen and (max-width: 850px) {
  .banner-content-right {
    display: none;
  }

  .banner-content {
    width: 85vw;
  }
}

@media only screen and (max-width: 470px) {
  .banner-content p {
    font-size: 3.1vw;
  }
}
