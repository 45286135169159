.schedule-area {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
  padding: 120px;
}

.schedule-area-child {
  width: 300px;
  height: 125px;
  display: flex;
  align-items: center;
}

.sch-text {
  margin-top: 18px;
  color: rgb(110, 109, 109);
}

.sch-text p {
  padding-left: 10px;
  margin: 0;
}

.sch-text p:first-child {
  font-size: 26px;
  color: rgb(49, 48, 48);
}

.schedule-area-jumper {
  width: 25vw;
  height: 100px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #17077b;
  padding: 30px;
}

.sch-jumper-link {
  text-decoration: none;
  margin-top: 40px;
}

.schedule-area-jumper p {
  color: white;
  padding-left: 20px;
  text-decoration: none;
}

@media only screen and (max-width: 958px) {
  .schedule-area {
    width: 95%;
  }
}

@media only screen and (max-width: 800px) {
  .schedule-area {
    flex-direction: column;
    height: 600px;
    margin: auto;
  }

  .schedule-area-jumper {
    width: 60vw;
  }
}
