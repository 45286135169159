.collapsable {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  width: 80%;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 5%;
}

.collapsable-tab {
  width: 100%;
  cursor: pointer;
  min-height: 3rem;
  line-height: 3rem;
  padding: 0 1rem;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.collapsable-arrow {
  border: solid #5c5c5c;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: transform 0.3s ease; /* Add transition for smooth rotation */
}

.collapsable-title {
  margin-left: 2%;
  font-size: 1.1em;
  font-weight: bold;
}

.collapsable-tab-info {
  display: none;
  max-height: auto;
  opacity: 0;
  overflow: hidden;
  transition: max-height 2s ease, opacity 2s ease;
}

.collapsable-tab-info.visible {
  max-height: auto;
  display: block;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 3%;
  opacity: 1;
}

.tab-image {
  width: 50%;
  height: auto;
}

.tab-htext {
  font-size: 2.2em;
  font-weight: lighter;
  margin-bottom: -15px;
}

.tab-ptext {
  line-height: 30px;
  font-size: large;
  margin-top: 3%;
}

.tab-blue {
  padding-left: 2%;
  margin-top: 4%;
  font-size: 2em;
  color: #17077b;
}

.tab-footer {
  text-align: center;
  width: 95%;
  margin: auto;
  line-height: 1.5;
  margin-bottom: 4%;
}
