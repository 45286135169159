.checklist {
  background-color: #eeeded;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 14%;
  padding: 4%;
  margin-bottom: 5%;
}

.checklist p {
  margin-bottom: 1.5em;
}

.checklist-content {
  display: flex;
}

.checklist-content p {
  margin-left: 3%;
}

.checklist-content img {
  margin-top: 2.1%;
}

@media only screen and (max-width: 978px) {
  .checklist {
    flex-direction: column;
  }

  .checklist-content img {
    margin-top: 1.2%;
  }
}
